import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: "app-fields-dialog",
  templateUrl: "./fields-dialog.component.html",
  styleUrls: ["./fields-dialog.component.scss"],
})
export class FieldsDialogComponent {
  public METADATA_FIELDS: object[] = [
    { field: "owner", placeholder: "User" },
    { field: "creationDate", placeholder: "Adding date" },
  ];
  public STANDARD_FIELDS: string[] = [
    "CUT",
    "REG",
    "TRD",
    "FKT",
    "SIG",
    "KEY",
    "MEL",
    "BEM",
  ];
  public ADDITIONAL_FIELDS: string[] = [
    "MEL_SEM",
    "MEL_RAW",
    "NO_REP",
    "RTM",
    "SCL_DEG",
    "SCL_SEM",
    "PHR_NO",
    "PHR_BARS", 
    "PHR_CAD",
    "ACC",
  ];
  private uncheckedFields: Set<String> = new Set<String>();

  constructor(
    public dialogRef: MatDialogRef<FieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: string[]
  ) {
    if (data) this.uncheckedFields = new Set<String>(data);
  }

  private updateChecked(field: string, event: any): void {
    !event.checked
      ? this.uncheckedFields.add(field)
      : this.uncheckedFields.delete(field);
  }

  public close(submit: boolean): void {
    this.dialogRef.close(
      submit ? Array.from(this.uncheckedFields.values()) : null
    );
  }
}
