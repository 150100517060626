import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { EncodingHelper } from "../shared/helpers/encoding-helper";
import * as FileSaver from "file-saver";

import { MainService } from "./services/main.service";
import { EsacService } from "./services/esac.service";
import { MessageDialogService } from "../shared/services/message-dialog.service";
import { AuthenticationService } from "../shared/services/authentication.service";

import { EsacAddDialogComponent } from "./esac-add-dialog/esac-add-dialog.component";
import { EsacConvertDialogComponent } from "./esac-convert-dialog/esac-convert-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { DownloadDialogComponent } from "./download-dialog/download-dialog.component";
import { FieldsDialogComponent } from "./fields-dialog/fields-dialog.component";
import { FindReplaceDialog } from "./find-replace-dialog/find-replace-dialog.component";

class SearchTerm {
  field: string;
  operator: string;
  values: string[] = [];
  constructor(field: string, value: string) {
    this.field = field;
    this.values = [value];
  }
}

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {
  public esacs = null;
  public esacsExpanded: boolean[] = [];
  public esacsSelectedIndexes: Set<number> = new Set<number>();
  public selectedIds = [];
  public searchTerm = "";
  public searchTerms: SearchTerm[] = [];
  public searchField;
  public searchOperator = "AND";
  public searchFields: Object[] = [
    { field: "name", placeholder: "Name" },
    { field: "title", placeholder: "CUT" },
    { field: "region", placeholder: "REG" },
    { field: "source", placeholder: "TRD" },
    { field: "function", placeholder: "FKT" },
    { field: "signature", placeholder: "SIG" },
    { field: "key", placeholder: "KEY" },
    { field: "melody", placeholder: "MEL" },
    { field: "remarks", placeholder: "BEM" },
    { field: "melodyRaw", placeholder: "MEL_RAW" },
    { field: "melodyRawSimple", placeholder: "NO_REP" },
    { field: "melodyRhythm", placeholder: "RTM" },
    { field: "melodyIntervals", placeholder: "MEL_SEM" },
    { field: "scl", placeholder: "SCL_DEG" },
    { field: "sclIntervals", placeholder: "SCL_SEM" },
    { field: "phraseNum", placeholder: "PHR_NO" },
    { field: "barsNumByPhrase", placeholder: "PHR_BARS" },
    { field: "cadByPhrase", placeholder: "PHR_CAD" },
    { field: "accByPhrase", placeholder: "ACC" },
  ];
  public page = 1;
  public pageSize: number = 10;
  public pages: Array<number> = new Array();
  public totalPages = 1;
  public pagingDisabled: boolean = false;
  public loading: boolean = true;
  public hiddenFields: string[] = [];
  private onDownloadError: any;
  private downloadTxt: any;
  private downloadZip: any;

  constructor(
    private mainService: MainService,
    public dialog: MatDialog,
    private esacService: EsacService,
    private messageDialogService: MessageDialogService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.readQueryParameters();
    this.getEsacs();
    this.readHiddenFields();
    this.onDownloadError = () =>
      this.messageDialogService.displayMessageDialog("Error downloading EsACs");
    this.downloadTxt = (data) =>
      FileSaver.saveAs(
        new Blob([data], {
          type: "text/plain;charset=UTF-8",
        }),
        "esacs.txt"
      );
    this.downloadZip = (data) =>
      FileSaver.saveAs(new Blob([data]), "esacs.zip");
  }

  readQueryParameters(): void {
    this.page = this.route.snapshot.queryParams["page"] || 1;
    this.pageSize = parseInt(this.route.snapshot.queryParams["size"]) || 10;
    var filtersEncoded = this.route.snapshot.queryParams["filters"];
    if (filtersEncoded) {
      this.searchTerms = JSON.parse(EncodingHelper.atou(filtersEncoded));
    }
  }

  setPage(i, event: any) {
    if (event) event.preventDefault;
    this.page = i;
    this.updatePages();
    this.pagingDisabled = true;
    this.updateUrl();
  }

  private getEsacs(): void {
    this.loading = true;
    this.mainService
      .getEsacs(this.page, this.pageSize, this.searchTerms)
      .subscribe(
        (data) => {
          this.totalPages = data.totalPages;
          if (this.page > this.totalPages && this.totalPages > 0) {
            this.setPage(this.totalPages, null);
          } else {
            this.updatePages();
            this.esacService.setEsacs(data.content);
            this.esacs = this.esacService.getEsacs();
            this.fillEsacsExpanded(this.esacs.length);
            this.pagingDisabled = false;
            this.loading = false;
          }
        },
        (error) => {
          this.messageDialogService.displayMessageDialog(
            "Error fetching EsACs"
          );
          this.pagingDisabled = false;
          this.loading = false;
        }
      );
  }

  private updatePages(): void {
    let toEnd = 2 - Math.min(2, this.totalPages - this.page);
    let fromStart = 2 - Math.min(2, this.page - 1);
    this.pages = Array.from(Array(this.totalPages), (x, i) => i + 1).filter(
      (p) =>
        (p <= this.page && this.page - toEnd - p <= 2) ||
        (p > this.page && p - this.page - fromStart <= 2)
    );
  }

  private updateUrl(): void {
    var queryString = `/?page=${this.page}&size=${this.pageSize}`;
    if (this.searchTerms.length > 0) {
      queryString += `&filters=${EncodingHelper.utoa(
        JSON.stringify(this.searchTerms)
      )}`;
    }
    this.location.go(queryString);
    this.getEsacs();
  }

  public isAnyEsac(): boolean {
    return !this.esacs || this.esacs.length === 0 ? false : true;
  }

  public search(page): void {
    if (!this.isSearchIncomplete()) {
      var term = this.searchTerms.filter((t) => t.field == this.searchField)[0];
      if (term) {
        term.operator = term.operator || this.searchOperator;
        term.values.push(this.searchTerm);
      } else {
        term = new SearchTerm(this.searchField, this.searchTerm);
        this.searchTerms.push(term);
      }
    }
    if (!page) this.page = 1;
    this.esacsSelectedIndexes.clear();
    this.updatePages();
    this.updateUrl();
    this.resetSearch();
  }

  private removeSearch(termIndex: number, valueIndex: number): void {
    var term = this.searchTerms[termIndex];
    term.values.splice(valueIndex, 1);
    if (term.values.length === 0) {
      this.searchTerms.splice(termIndex, 1);
    }
    if (term.values.length === 1) {
      term.operator = undefined;
    }
    this.page = 1;
    this.esacsSelectedIndexes.clear();
    this.updatePages();
    this.updateUrl();
  }

  public isAtLeastOneValue(): boolean {
    return (
      this.searchTerms.filter((t) => t.field === this.searchField).length > 0
    );
  }

  private isOperatorSelected(): boolean {
    var term = this.searchTerms.filter((t) => t.field === this.searchField)[0];
    return (
      this.searchTerms.filter((t) => t.field === this.searchField)[0]
        .operator != undefined
    );
  }

  private getOperator(field: string): string {
    var term = this.searchTerms.filter((t) => t.field === field)[0];
    return term.operator;
  }

  private getPlaceholder(field: string): string {
    return (
      this.searchFields.filter((t) => t["field"] === field)[0]["placeholder"] ||
      field
    );
  }

  public onSearchFieldChange(value): void {
    var term = this.searchTerms.filter((t) => t.field === this.searchField)[0];
    if (term && term.operator) {
      this.searchOperator = term.operator;
    }
  }

  public onPageSizeChange(): void {
    this.updateUrl();
  }

  private resetSearch(): void {
    this.searchTerm = "";
    this.searchField = undefined;
    this.searchOperator = undefined;
  }

  public isSearchIncomplete(): boolean {
    return this.searchField === undefined || this.searchTerm === "";
  }

  private fillEsacsExpanded(length: number): void {
    for (let i = 0; i < length; i++) {
      this.esacsExpanded.push(false);
    }
  }

  private toggleCard(index: number): void {
    this.esacsExpanded[index] = !this.esacsExpanded[index];
  }

  public expandAll(): void {
    this.esacsExpanded.fill(true);
  }

  public closeAll(): void {
    this.esacsExpanded.fill(false);
  }

  public readHiddenFields() {
    var savedHiddenFields = localStorage.getItem("hiddenFields");
    if (savedHiddenFields) {
      this.hiddenFields = JSON.parse(savedHiddenFields);
    }
  }

  public showFieldsDialog(): void {
    this.dialog
      .open(FieldsDialogComponent, { data: this.hiddenFields })
      .afterClosed()
      .subscribe((hiddenFields) => {
        if (hiddenFields != null) {
          this.hiddenFields = hiddenFields;
          localStorage.setItem(
            "hiddenFields",
            JSON.stringify(this.hiddenFields)
          );
        }
      });
  }

  addEsac(): void {
    const dialogRef = this.dialog.open(EsacAddDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      width: "80%",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(() => {
      this.search(this.page);
    });
  }

  handleEsacUpdate(): void {
    this.search(this.page);
  }

  handleEsacChecked(esac): void {
    this.esacsSelectedIndexes.add(esac.id);
  }

  handleEsacUnchecked(esac): void {
    this.esacsSelectedIndexes.delete(esac.id);
  }

  convertEsac(index: number): void {
    const esacs = this.esacService.getEsacs();

    this.dialog.open(EsacConvertDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: esacs,
    });
  }

  private download(page, asZip = false): void {
    this.dialog
      .open(DownloadDialogComponent)
      .afterClosed()
      .subscribe((fields) => {
        if (fields == null) return;
        if (asZip) {
          this.mainService
            .getEsacsZip(page, this.pageSize, this.searchTerms, fields)
            .subscribe(this.downloadZip, this.onDownloadError);
        } else {
          this.mainService
            .getEsacsTxt(page, this.pageSize, this.searchTerms, fields)
            .subscribe(this.downloadTxt, this.onDownloadError);
        }
      });
  }

  private downloadSelected(asZip = false): void {
    this.dialog
      .open(DownloadDialogComponent)
      .afterClosed()
      .subscribe((fields) => {
        if (fields == null) return;
        if (asZip) {
          this.mainService
            .getEsacsZipByIds(Array.from(this.esacsSelectedIndexes), fields)
            .subscribe(this.downloadZip, this.onDownloadError);
        } else {
          this.mainService
            .getEsacsTxtByIds(Array.from(this.esacsSelectedIndexes), fields)
            .subscribe(this.downloadTxt, this.onDownloadError);
        }
      });
  }

  private analytic(page): void {
    var filtersEncoded;
    if (this.searchTerms.length) {
      filtersEncoded = EncodingHelper.utoa(JSON.stringify(this.searchTerms));
    }
    const size = page ? this.pageSize : undefined;
    this.router.navigate(["analytic"], {
      queryParams: { filters: filtersEncoded, page: page, size: size },
    });
  }

  private analyticSelected(): void {
    const ids = Array.from(this.esacsSelectedIndexes).join();
    this.router.navigate(["analytic"], { queryParams: { ids: ids } });
  }

  private askToDeleteFiltered(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: "Are you sure you want to delete all EsACs matching current search criteria?",
      },
    });
    this.delete(dialogRef, () =>
      this.mainService.deleteEsacs(this.searchTerms)
    );
  }

  private findAndReplace(page): void {
    const size = page ? this.pageSize : undefined;
    this.dialog.open(FindReplaceDialog, {
      data: {
        filters: this.searchTerms,
        page,
        pageSize: size,
        afterClosed: () => this.handleEsacUpdate(),
      },
    });
  }

  private findAndReplaceSelected(): void {
    this.dialog.open(FindReplaceDialog, {
      data: {
        selected: this.esacsSelectedIndexes,
        afterClosed: () => this.handleEsacUpdate(),
      },
    });
  }

  private askToDeleteSelected(): void {
    const ids = Array.from(this.esacsSelectedIndexes);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        text: `${ids.length} EsAC${
          ids.length == 1 ? " is" : "s are"
        } selected. Are you sure you want to continue?`,
      },
    });
    this.delete(dialogRef, () => this.mainService.deleteEsacsByIds(ids));
  }

  private delete(dialogRef, deleteCallback): void {
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.loading = true;
        deleteCallback().subscribe(
          (data) => {
            this.search(this.page);
            let dialogRef = this.messageDialogService.displayMessageDialog(
              `${data} EsAC${data == 1 ? " was" : "s were"} deleted`
            );
          },
          (error) => {
            this.loading = false;
            this.messageDialogService.displayMessageDialog(
              "Error while deleting EsACs"
            );
          }
        );
      }
    });
  }
}
