import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MainService } from "../services/main.service";

import {
  keyTonic_regexp,
  keyShortestValue_regexp,
} from "../../shared/consts/regexp";

@Component({
  selector: "app-esac-recode-dialog",
  templateUrl: "./esac-recode-dialog.component.html",
  styleUrls: ["./esac-recode-dialog.component.scss"],
})
export class EsacRecodeDialogComponent implements OnInit {
  public form: FormGroup;
  public errors: String[];
  public loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EsacRecodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.form = this.fillForm();
  }

  public recodeEsac(): void {
    this.errors = [];
    this.loading = true;
    this.setFormEnabled(false);
    this.mainService
      .recodeEsac(
        this.data.id,
        this.form.value.tonic,
        this.form.value.shortestValue
      )
      .subscribe(
        (data) => {
          if (data.totalSuccesses) {
            this.dialogRef.close({
              triedToUpdate: true,
              esac: data.successes[0],
            });
          } else {
            this.errors = data.failures[0].errors;
            this.loading = false;
            this.setFormEnabled(true);
          }
        },
        (error) => {
          this.dialogRef.close({ triedToUpdate: true });
          this.loading = false;
          this.setFormEnabled(true);
        }
      );
  }

  private fillForm(): FormGroup {
    const esac = this.data;
    return new FormGroup({
      tonic: new FormControl(esac.newKey.tonic, [
        Validators.required,
        Validators.pattern(keyTonic_regexp),
      ]),
      shortestValue: new FormControl(esac.newKey.shortestValue, [
        Validators.required,
        Validators.pattern(keyShortestValue_regexp),
      ]),
    });
  }

  private setFormEnabled(value: boolean): void {
    if (value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }
}
