import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as FileSaver from "file-saver";

import { MainService } from "./../../main/services/main.service";
import { MidiPlayerService } from "./../../main/services/midi-player.service";
import { MessageDialogService } from "../../shared/services/message-dialog.service";

@Component({
  selector: "app-esac-convert-result",
  templateUrl: "./esac-convert-result.component.html",
  styleUrls: ["./esac-convert-result.component.scss"],
})
export class EsacConvertResultComponent implements OnInit, OnDestroy {
  @Input() esac;

  private createNewEsacFromURL = "/api/esac";
  public speedData = this.midiPlayerService.getSpeedData();
  public errors: String[];
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    private midiPlayerService: MidiPlayerService,
    private messageDialogService: MessageDialogService
  ) {}

  ngOnInit() {
    this.esac.isPlaying = false;
  }

  ngOnDestroy() {
    this.stopMidi();
  }

  public playMidi(esac): void {
    this.mainService.esacToMidi(esac).subscribe(
      (data) => {
        this.esac.isPlaying = true;
        this.midiPlayerService.setMidiSong(data, this.esac.id);
        this.midiPlayerService.playMidi();
      },
      (error) => {
        this.messageDialogService.displayMessageDialog("Invalid EsAC");
      }
    );
  }

  public stopMidi(): void {
    this.esac.isPlaying = false;
    this.midiPlayerService.stopMidi();
  }

  public isMidiPlaying(): boolean {
    return (
      this.checkEsacId() &&
      this.esac.isPlaying &&
      this.midiPlayerService.isMidiPlaying()
    );
  }

  private checkEsacId(): boolean {
    return this.esac.id === this.midiPlayerService.getEsacId();
  }

  public downloadMidi(esac): void {
    this.mainService.esacToMidiFile(esac).subscribe(
      (data) => {
        const blob = new Blob([data], { type: "audio/midi" });
        FileSaver.saveAs(blob, esac.signature + "_" + esac.title + ".mid");
      },
      (error) => {
        this.messageDialogService.displayMessageDialog(
          "Error downloading EsAC"
        );
      }
    );
  }

  public addEsac(): any {
    this.errors = [];
    this.loading = true;
    return this.http.put(this.createNewEsacFromURL, this.esac).subscribe(
      (data) => {
        const response: any = data;
        if (response.totalSuccesses === 1) {
          this.messageDialogService.displayMessageDialog(
            "EsAC added successfully"
          );
        } else {
          this.errors = response.failures[0].errors;
          this.loading = false;
        }
      },
      (error) => {
        this.messageDialogService.displayMessageDialog("Error adding EsAC");
        this.loading = false;
      }
    );
  }

  public slowDownMidi(): void {
    this.midiPlayerService.slowDownMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  public speedUpMidi(): void {
    this.midiPlayerService.speedUpMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }
}
