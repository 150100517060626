import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import * as FileSaver from "file-saver";

@Component({
  selector: "find-replace-result-dialog",
  templateUrl: "./find-replace-result-dialog.component.html",
})
export class FindReplaceResultDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public result: any) {}

  public downloadErrors(): void {
    var content = "";
    for (let i = 0; i < this.result.failures.length; i++) {
      const failure = this.result.failures[i];
      content += failure.song.name != null ? failure.song.name + "\r\n" : "";
      content +=
        failure.song.title != null ? "CUT[" + failure.song.title + "]\r\n" : "";
      content +=
        failure.song.region != null
          ? "REG[" + failure.song.region + "]\r\n"
          : "";
      content +=
        failure.song.source != null
          ? "TRD[" + failure.song.source + "]\r\n"
          : "";
      content +=
        failure.song.function != null
          ? "FKT[" + failure.song.function + "]\r\n"
          : "";
      content +=
        failure.song.signature != null
          ? "SIG[" + failure.song.signature + "]\r\n"
          : "";
      content +=
        failure.song.key != null ? "KEY[" + failure.song.key + "]\r\n" : "";
      content +=
        failure.song.melody != null
          ? "MEL[" + failure.song.melody.replace(/\n/g, "\r\n    ") + "]\r\n"
          : "";
      content +=
        failure.song.remarks != null
          ? "BEM[" + failure.song.remarks + "]\r\n"
          : "";
      content += "\r\n";
      for (let j = 0; j < failure.errors.length; j++) {
        content += `${failure.errors[j]}\r\n`;
      }
      content += `\r\n\r\n`;
    }
    const blob = new Blob([content], { type: "text/plain;charset=UTF-8" });
    FileSaver.saveAs(blob, "errors.txt");
  }
}
