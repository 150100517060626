import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MainService } from './../services/main.service';

import { melody_regexp, keySig_regexp, keyShortestValue_regexp, keyTonic_regexp, keyMetre_regexp } from '../../shared/consts/regexp';

@Component({
  selector: 'app-esac-edit-dialog',
  templateUrl: './esac-edit-dialog.component.html',
  styleUrls: ['./esac-edit-dialog.component.scss']
})
export class EsacEditDialogComponent implements OnInit {

  public form: FormGroup;
  public errors: String[];
  public loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EsacEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mainService: MainService
  ) { }

  ngOnInit() {
    this.form = this.fillForm();
  }

  public editEsac(): void {
    this.errors = [];
    this.loading = true;
    this.setFormEnabled(false);
    this.mainService.updateEsac(this.data.id, this.form.value)
      .subscribe(data => {
        if (data.totalSuccesses) {
          this.dialogRef.close({ triedToUpdate: true, esac: data.successes[0] });
        } else {
          this.errors = data.failures[0].errors;
          this.loading = false;
          this.setFormEnabled(true);
        }
      }, error => {
        this.dialogRef.close({ triedToUpdate: true });
        this.loading = false;
        this.setFormEnabled(true);
      });
  }

  private fillForm(): FormGroup {
    const esac = this.data;
    return new FormGroup({
      name: new FormControl(esac.name),
      title: new FormControl(esac.title),
      source: new FormControl(esac.source),
      region: new FormControl(esac.region),
      function: new FormControl(esac.function),
      signature: new FormControl(esac.signature),
      newKey: new FormGroup({
        sig: new FormControl(esac.newKey.sig, [Validators.required, Validators.pattern(keySig_regexp)]),
        shortestValue: new FormControl(esac.newKey.shortestValue, [Validators.required, Validators.pattern(keyShortestValue_regexp)]),
        tonic: new FormControl(esac.newKey.tonic, [Validators.required, Validators.pattern(keyTonic_regexp)]),
        metre: new FormControl(esac.newKey.metre, [Validators.required, Validators.pattern(keyMetre_regexp)])
      }),
      melody: new FormControl(esac.melody, [
        Validators.required,
        Validators.pattern(melody_regexp)
      ]),
      remarks: new FormControl(esac.remarks)
    });
  }

  private setFormEnabled(value: boolean): void {
    if (value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }
}
