import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-esac-add-dialog',
  templateUrl: './esac-add-dialog.component.html',
  styleUrls: ['./esac-add-dialog.component.scss']
})
export class EsacAddDialogComponent implements OnInit {

  public step = 0;
  private lastStep = 2;
  private sourceType: number;
  public progress = 0;
  public text;
  public totalSuccesses: number;
  public totalFailures: number;
  public failures = [];

  constructor(
    public dialogRef: MatDialogRef<EsacAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  receiveMessage($event) {
    this.step++;
    this.updateProgress();
    this.totalSuccesses = $event.totalSuccesses;
    this.totalFailures = $event.totalFailures;
    this.failures = $event.failures;
  }

  public chooseSource(source: number): void {
    this.sourceType = source;
    this.step++;
    this.updateProgress();
  }

  public prevStep(): void {
    if (this.step > 0) {
      this.step--;
    }
    this.updateProgress();
  }

  public updateProgress(): void {
    this.progress = this.step * 100 / this.lastStep;
  }

  public downloadErrors():void{
    var content = '';
    for(var i=0;i<this.failures.length;i++){
      var failure = this.failures[i];
      content += failure.song.name!=null ? failure.song.name + '\r\n' : "";
      content += failure.song.title!=null ? 'CUT[' + failure.song.title + ']\r\n' : "";
      content += failure.song.region!=null ? 'REG[' + failure.song.region + ']\r\n': "";
      content += failure.song.source!=null ? 'TRD[' + failure.song.source + ']\r\n': "";
      content += failure.song.function!=null ? 'FKT[' + failure.song.function + ']\r\n': "";
      content += failure.song.signature!=null ? 'SIG[' + failure.song.signature + ']\r\n': "";
      content += failure.song.key!=null ? 'KEY[' + failure.song.key + ']\r\n': "";
      content += failure.song.melody!=null ? 'MEL[' + failure.song.melody.replace(/\n/g, '\r\n    ') + ']\r\n': "";
      content += failure.song.remarks!=null ? 'BEM[' + failure.song.remarks + ']\r\n': "";
      content += '\r\n';
      for(var j=0;j<failure.errors.length;j++){
        content+=`${failure.errors[j]}\r\n`;
      }
      content+=`\r\n\r\n`
    }
    const blob = new Blob([content], { type: 'text/plain;charset=UTF-8' });
    FileSaver.saveAs(blob, 'errors.txt');
  }
}
