import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { Router } from "@angular/router";

import { MatDialog } from "@angular/material";

import * as FileSaver from "file-saver";

import { MainService } from "./../services/main.service";
import { EsacService } from "./../services/esac.service";
import { MidiPlayerService } from "./../services/midi-player.service";
import { MessageDialogService } from "../../shared/services/message-dialog.service";
import { AuthenticationService } from "../../shared/services/authentication.service";

import { OneEsacConvertDialogComponent } from "./../one-esac-convert-dialog/one-esac-convert-dialog.component";
import { EsacEditDialogComponent } from "./../esac-edit-dialog/esac-edit-dialog.component";
import { EsacRecodeDialogComponent } from "./../esac-recode-dialog/esac-recode-dialog.component";
import { ConfirmDialogComponent } from "./../confirm-dialog/confirm-dialog.component";
import { NotesDialogComponent } from "../notes-dialog/notes-dialog.component";
import { DownloadDialogComponent } from "./../download-dialog/download-dialog.component";

@Component({
  selector: "app-main-card",
  templateUrl: "./main-card.component.html",
  styleUrls: ["./main-card.component.scss"],
})
export class MainCardComponent implements OnInit, OnDestroy {
  constructor(
    private mainService: MainService,
    public dialog: MatDialog,
    public esacService: EsacService,
    private midiPlayerService: MidiPlayerService,
    private messageDialogService: MessageDialogService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  @Input() esac: any;
  @Input() isExpanded: boolean;
  @Input() isSelected: boolean;
  @Input() hiddenFields: string[] = [];
  @Output() updated = new EventEmitter();
  @Output() checked = new EventEmitter<number>();
  @Output() unchecked = new EventEmitter<number>();
  public speedData = this.midiPlayerService.getSpeedData();

  ngOnInit() {}

  ngOnDestroy() {
    this.stopMidi();
  }

  convertEsac(index: number): void {
    const esac = this.esac;

    const dialogRef = this.dialog.open(OneEsacConvertDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: esac,
    });
  }

  showNotes(): void {
    const esac = this.esac;

    this.dialog.open(NotesDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: esac,
    });
  }

  public playMidi(): void {
    this.mainService.esacToMidi(this.esac).subscribe(
      (data) => {
        this.speedData = this.midiPlayerService.getSpeedData();
        this.esac.isPlaying = true;
        this.midiPlayerService.setMidiSong(data, this.esac.id);
        this.midiPlayerService.playMidi();
      },
      (error) => {
        this.messageDialogService.displayMessageDialog("Invalid EsAC");
      }
    );
  }

  public stopMidi(): void {
    this.esac.isPlaying = false;
    this.midiPlayerService.stopMidi();
  }

  public isMidiPlaying(): boolean {
    return (
      this.checkEsacId() &&
      this.esac.isPlaying &&
      this.midiPlayerService.isMidiPlaying()
    );
  }

  private checkEsacId(): boolean {
    return this.esac.id === this.midiPlayerService.getEsacId();
  }

  public slowDownMidi(): void {
    this.midiPlayerService.slowDownMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  public speedUpMidi(): void {
    this.midiPlayerService.speedUpMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  editEsac(): void {
    const esac = this.esac;
    const dialogRef = this.dialog.open(EsacEditDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: esac,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.triedToUpdate) {
        if (result.esac) {
          this.esac = result.esac;
          this.messageDialogService.displayMessageDialog(
            "EsAC edited successfully"
          );
          this.updated.emit();
        } else {
          this.messageDialogService.displayMessageDialog("Error editing EsAC");
        }
      }
    });
  }

  recodeEsac(): void {
    const esac = this.esac;
    const dialogRef = this.dialog.open(EsacRecodeDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: esac,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.triedToUpdate) {
        if (result.esac) {
          this.esac = result.esac;
          this.messageDialogService.displayMessageDialog(
            "EsAC melody recoded successfully"
          );
          this.updated.emit();
        } else {
          this.messageDialogService.displayMessageDialog(
            "Error recoding EsAC melody"
          );
        }
      }
    });
  }

  deleteEsac(esacId: string): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      autoFocus: false,
      minWidth: 300,
      disableClose: true,
      data: {
        text: "Are you sure you want to delete this EsAC?",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.mainService.deleteEsac(esacId).subscribe(
          () => {
            this.esacService.deleteEsac(esacId);
            this.messageDialogService.displayMessageDialog(
              "EsAC deleted successfully"
            );
          },
          (error) => {
            this.messageDialogService.displayMessageDialog(
              "Error deleting EsAC"
            );
          }
        );
      }
    });
  }

  private downloadEsac(): void {
    this.dialog
      .open(DownloadDialogComponent)
      .afterClosed()
      .subscribe((fields) => {
        if (fields == null) return;
        const esac = this.esac;
        const content = this.esacToString(esac, fields);
        const blob = new Blob([content], { type: "text/plain" });
        FileSaver.saveAs(blob, esac.signature + "_" + esac.title + ".txt");
      });
  }

  private esacToString(esac, fields): string {
    fields = fields || ["CUT", "REG", "TRD", "SIG", "KEY", "MEL", "BEM"];
    let string = "";
    string += esac.name != null ? esac.name + "\r\n" : "";
    string +=
      esac.title != null && fields.includes("CUT")
        ? "CUT[" + esac.title + "]\r\n"
        : "";
    string +=
      esac.region != null && fields.includes("REG")
        ? "REG[" + esac.region + "]\r\n"
        : "";
    string +=
      esac.source != null && fields.includes("TRD")
        ? "TRD[" + esac.source + "]\r\n"
        : "";
    string +=
      esac.function != null && fields.includes("FKT")
        ? "FKT[" + esac.function + "]\r\n"
        : "";
    string +=
      esac.signature != null && fields.includes("SIG")
        ? "SIG[" + esac.signature + "]\r\n"
        : "";
    string +=
      esac.key != null && fields.includes("KEY")
        ? "KEY[" + esac.key + "]\r\n"
        : "";
    string +=
      esac.melody != null && fields.includes("MEL")
        ? "MEL[" + esac.melody.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.remarks != null && fields.includes("BEM")
        ? "BEM[" + esac.remarks + "]\r\n"
        : "";
    string +=
      esac.melodyIntervals != null && fields.includes("MEL_SEM")
        ? "MEL_SEM[" + esac.melodyIntervals.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRaw != null && fields.includes("MEL_RAW")
        ? "MEL_RAW[" + esac.melodyRaw.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRawSimple != null && fields.includes("NO_REP")
        ? "NO_REP[" + esac.melodyRawSimple.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRhythm != null && fields.includes("RTM")
        ? "RTM[" + esac.melodyRhythm.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.scl != null && fields.includes("SCL_DEG")
        ? "SCL_DEG[" + esac.scl + "]\r\n"
        : "";
    string +=
      esac.sclIntervals != null && fields.includes("SCL_SEM")
        ? "SCL_SEM[" + esac.sclIntervals + "]\r\n"
        : "";
    string +=
      esac.phraseNum != null && fields.includes("PHR_NO")
        ? "PHR_NO[" + esac.phraseNum + "]\r\n"
        : "";
    string +=
      esac.barsNumByPhrase != null && fields.includes("PHR_BARS")
        ? "PHR_BARS[" + esac.barsNumByPhrase + "]\r\n"
        : "";
    string +=
      esac.cadByPhrase != null && fields.includes("PHR_CAD")
        ? "PHR_CAD[" + esac.cadByPhrase + "]\r\n"
        : "";
    string +=
      esac.accByPhrase != null && fields.includes("ACC")
        ? "ACC[" + esac.accByPhrase + "]\r\n"
        : "";
    string += "\r\n";

    return string;
  }

  public toggleCard(): void {
    this.isExpanded = !this.isExpanded;
    if (!this.isExpanded && this.esac.isPlaying) {
      this.stopMidi();
    }
  }

  public selectionChanged(event: any): void {
    this.isSelected = event.checked;
    if (this.isSelected) {
      this.checked.emit(this.esac);
    } else {
      this.unchecked.emit(this.esac);
    }
  }

  public hasWriteRights(): boolean {
    var loggedUser = this.authenticationService.getLoggedUser();
    if (!loggedUser) {
      return false;
    }
    if (!this.esac.owner) {
      return loggedUser.admin;
    }
    return loggedUser.admin || this.esac.owner.email == loggedUser.email;
  }

  public analytic(): void {
    this.router.navigate(["analytic"], { queryParams: { ids: this.esac.id } });
  }
}
