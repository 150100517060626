import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatProgressBarModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatCardModule,
  MatSliderModule,
  MatTooltipModule,
  MatSelectModule
} from '@angular/material';

import { SharedModule } from '../shared/shared.module';
import { AuthenticationService } from '../shared/services/authentication.service';
import { RegisterComponent } from './register.component';
import { RegisterConfirmComponent } from './confirm/register.confirm.component'

const appRoutes: Routes = [
    { path: 'register', component: RegisterComponent },
    { path: 'registrationConfirm', component: RegisterConfirmComponent}
  ];
  
  @NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatProgressBarModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      MatChipsModule,
      MatCardModule,
      MatSliderModule,
      MatTooltipModule,
      MatSelectModule,
      SharedModule,
      RouterModule.forChild(appRoutes)
    ],
    declarations: [
      RegisterComponent,
      RegisterConfirmComponent
    ],
    providers: [AuthenticationService]
  })
  export class RegisterModule { }
  