import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
    selector: 'app-register-confirm',
    templateUrl: './register.confirm.component.html',
    styleUrls: ['./register.confirm.component.scss']
})
export class RegisterConfirmComponent implements OnInit {

    token: string;
    loading: boolean = true;
    error: boolean = false;

    constructor(private route: ActivatedRoute, private authenticationService: AuthenticationService) {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit() {
        this.authenticationService.verifyToken(this.token).subscribe(
            data => {
                this.loading = false;
            }, error => {
                this.loading = false;
                this.error = true;
            });
    }

    isSuccess(): boolean {
        return !this.loading && !this.error;
    }

    isError(): boolean {
        return !this.loading && this.error;
    }
}
