import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    successful = false;
    returnUrl: string;
    error = '';

    constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
    }

    get f() { return this.form.controls; }

    get isError() {
        return this.error;
    }

    getErrorMessage(field) {
        if (field.hasError('required')) return 'You must enter a value';
        if (field.hasError('email')) return 'Not a valid email';
    }

    onSubmit() {
        this.submitted = true;
        this.error = '';

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.f.email.disable();
        this.authenticationService.resetPassword(this.f.email.value)
            .subscribe(
                data => {
                    this.successful = true;
                },
                error => {
                    this.error = 'There was a problem with processing your request. Please make sure you provided a correct email';
                    this.loading = false;
                    this.f.email.enable();
                });
    }
}
