import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { melody_regexp, keySig_regexp, keyShortestValue_regexp, keyTonic_regexp, keyMetre_regexp } from '../../../shared/consts/regexp';

@Component({
  selector: 'app-esac-add-new',
  templateUrl: './esac-add-new.component.html',
  styleUrls: ['./esac-add-new.component.scss']
})
export class EsacAddNewComponent implements OnInit {

  public form: FormGroup;
  public errors: String[];
  public loading: boolean = false;
  private createNewEsacFromURL = '/api/esac';

  @Output() isSubmited = new EventEmitter<object>();

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.form = this.newForm();
  }

  public addEsac(): any {
    this.errors = [];
    this.loading = true;
    this.setFormEnabled(false);
    return this.http.put(this.createNewEsacFromURL, this.form.value).subscribe(data => {
      const response: any = data;
      if (response.totalSuccesses == 1) {
        this.isSubmited.emit(response);
      } else {
        this.errors = response.failures[0].errors;
        this.loading = false;
        this.setFormEnabled(true);
      }
    },
      error => {
        this.isSubmited.emit({ totalSuccesses: 0, totalFailures: 0, failures: [] });
        this.loading = false;
        this.setFormEnabled(true);
      });
  }

  private newForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      title: new FormControl(''),
      source: new FormControl(''),
      region: new FormControl(''),
      function: new FormControl(''),
      signature: new FormControl(''),
      newKey: new FormGroup({
        sig: new FormControl('', [Validators.required, Validators.pattern(keySig_regexp)]),
        shortestValue: new FormControl('', [Validators.required, Validators.pattern(keyShortestValue_regexp)]),
        tonic: new FormControl('', [Validators.required, Validators.pattern(keyTonic_regexp)]),
        metre: new FormControl('', [Validators.required, Validators.pattern(keyMetre_regexp)])
      }),
      melody: new FormControl('', [
        Validators.required,
        Validators.pattern(melody_regexp)
      ]),
      remarks: new FormControl('')
    });
  }

  private setFormEnabled(value: boolean): void {
    if (value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }
}
