import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-download-dialog",
  templateUrl: "./download-dialog.component.html",
  styleUrls: ["./download-dialog.component.scss"],
})
export class DownloadDialogComponent {
  public STANDARD_FIELDS: string[] = [
    "CUT",
    "REG",
    "TRD",
    "FKT",
    "SIG",
    "KEY",
    "MEL",
    "BEM",
  ];
  public ADDITIONAL_FIELDS: string[] = [
    "MEL_SEM",
    "MEL_RAW",
    "NO_REP",
    "RTM",
    "SCL_DEG",
    "SCL_SEM",
  ];
  private checkedFields: Set<String> = new Set<String>(this.STANDARD_FIELDS);

  constructor(public dialogRef: MatDialogRef<DownloadDialogComponent>) {}

  private updateChecked(field: string, event: any): void {
    event.checked
      ? this.checkedFields.add(field)
      : this.checkedFields.delete(field);
  }

  public close(submit: boolean): void {
    this.dialogRef.close(
      submit ? Array.from(this.checkedFields.values()) : null
    );
  }
}
