import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MessageDialogService } from '../../shared/services/message-dialog.service';

import { ConverterService } from '../services/converter.service';
import { melody_regexp, keySig_regexp, keyShortestValue_regexp, keyTonic_regexp, keyMetre_regexp } from '../../shared/consts/regexp';

@Component({
  selector: 'app-esac-convert-new',
  templateUrl: './esac-convert-new.component.html',
  styleUrls: ['./esac-convert-new.component.scss']
})
export class EsacConvertNewComponent implements OnInit {

  public form: FormGroup;
  public converting = false;
  public errors: String[];
  public loading: boolean = false;
  private validateEsacFromURL = '/api/esac/validate';
  @Output() converted = new EventEmitter();

  constructor(
    private converterService: ConverterService,
    private http: HttpClient,
    private messageDialogService: MessageDialogService
  ) { }

  ngOnInit() {
    this.form = this.newForm();
  }

  public submit(): void {
    this.errors = [];
    this.loading = true;
    this.setFormEnabled(false);
    this.http.post(this.validateEsacFromURL, this.form.value).subscribe(data => {
      const response: any = data;
      if (response.errors.length == 0) {
        this.converted.emit(response.song)
      } else {
        this.errors = response.errors;
        this.loading = false;
        this.setFormEnabled(true);
      }
    },
      error => {
        this.messageDialogService.displayMessageDialog('Error while validating EsAC');
        this.loading = false;
        this.setFormEnabled(true);
      });
  }

  private newForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(''),
      title: new FormControl(''),
      source: new FormControl(''),
      region: new FormControl(''),
      function: new FormControl(''),
      signature: new FormControl(''),
      newKey: new FormGroup({
        sig: new FormControl('', [Validators.required, Validators.pattern(keySig_regexp)]),
        shortestValue: new FormControl('', [Validators.required, Validators.pattern(keyShortestValue_regexp)]),
        tonic: new FormControl('', [Validators.required, Validators.pattern(keyTonic_regexp)]),
        metre: new FormControl('', [Validators.required, Validators.pattern(keyMetre_regexp)])
      }),
      melody: new FormControl('', [
        Validators.required,
        Validators.pattern(melody_regexp)
      ]),
      remarks: new FormControl('')
    });
  }

  private setFormEnabled(value: boolean): void {
      if(value){
        this.form.enable();
      }else{
        this.form.disable();
      }
  }

}
