import { Component, OnInit, AfterViewInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { MidiPlayerService } from "./../services/midi-player.service";
import { MainService } from "./../services/main.service";
import { MessageDialogService } from "../../shared/services/message-dialog.service";

@Component({
  selector: "notes-dialog",
  templateUrl: "./notes-dialog.component.html",
  styleUrls: ["./notes-dialog.component.scss"],
})
export class NotesDialogComponent implements OnInit, AfterViewInit {
  public esac: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mainService: MainService,
    private midiPlayerService: MidiPlayerService,
    private messageDialogService: MessageDialogService
  ) {}
  public speedData = this.midiPlayerService.getSpeedData();

  ngOnInit() {
    this.esac = this.data;
  }

  ngAfterViewInit() {
    this.populateCanvas();
  }

  ngOnDestroy() {
    this.stopMidi();
  }

  populateCanvas() {
    var es = new EsACgraph();
    var tlt =
      `CUT[${this.esac.title}]\n` +
      `KEY[${this.esac.key}]\n` +
      `MEL[${this.esac.melody.replace(/\r/g, "").replace(/\n/g, "\n    ")}]\n`;

    es.setMel(tlt);
    es.setCanvasID("canvas");
    es.setBackgroundColor("#FFFFFF");
    es.setWidth(800);
    es.graphIt();
  }

  playMidi() {
    this.mainService.esacToMidi(this.esac).subscribe(
      (data) => {
        this.speedData = this.midiPlayerService.getSpeedData();
        this.esac.isPlaying = true;
        this.midiPlayerService.setMidiSong(data, this.esac.id);
        this.midiPlayerService.playMidi();
      },
      () => {
        this.messageDialogService.displayMessageDialog("Invalid EsAC");
      }
    );
  }

  public stopMidi(): void {
    this.esac.isPlaying = false;
    this.midiPlayerService.stopMidi();
  }

  public isMidiPlaying(): boolean {
    return (
      this.checkEsacId() &&
      this.esac.isPlaying &&
      this.midiPlayerService.isMidiPlaying()
    );
  }

  private checkEsacId(): boolean {
    return this.esac.id === this.midiPlayerService.getEsacId();
  }

  public slowDownMidi(): void {
    this.midiPlayerService.slowDownMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  public speedUpMidi(): void {
    this.midiPlayerService.speedUpMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }
}
