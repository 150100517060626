import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../shared/services/authentication.service';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-changepassword',
    templateUrl: './changepassword.component.html',
    styleUrls: ['./changepassword.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    successful = false;
    error = '';
    token: string;

    constructor(private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder) {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            password: new FormControl('', [Validators.required]),
            passwordRepeat: new FormControl('', [Validators.required])
        });
    }

    get f() { return this.form.controls; }

    get isError() {
        return this.error;
    }

    getErrorMessage(field) {
        if (field.hasError('required')) return 'You must enter a value';
    }

    onSubmit() {
        this.submitted = true;
        this.error = '';

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }
        if (this.f.password.value !== this.f.passwordRepeat.value) {
            this.error = "Provided passwords don't match"
            return;
        }

        this.loading = true;
        this.f.password.disable();
        this.f.passwordRepeat.disable();
        this.authenticationService.changePassword(this.token, this.f.password.value)
            .subscribe(
                data => {
                    this.successful = true;
                },
                error => {
                    this.error = 'There was a problem with processing your request';
                    this.loading = false;
                    this.f.password.enable();
                    this.f.passwordRepeat.enable();
                });
    }
}
