import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule, MatToolbarModule, MatIconModule } from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthInterceptor } from './shared/helpers/basic-auth.interceptor';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { ConverterModule } from './converter/converter.module';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module'
import { ForgotPasswordModule } from './forgotPassword/forgotpassword.module'
import { ChangePasswordModule } from './changePassword/changepassword.module'
import { AnalyticModule } from './analytic/analytic.module'
import { MatMenuModule } from '@angular/material/menu';

const appRoutes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MainModule,
    ConverterModule,
    LoginModule,
    RegisterModule,
    ForgotPasswordModule,
    ChangePasswordModule,
    AnalyticModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
