import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatProgressBarModule,
  MatIconModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatChipsModule,
  MatCardModule,
  MatSliderModule,
  MatTooltipModule,
  MatSelectModule,
  MatTableModule,
  MatSortModule
} from '@angular/material';

import { SharedModule } from '../shared/shared.module';
import { AnalyticComponent } from './analytic.component';

const appRoutes: Routes = [
    { path: 'analytic', component: AnalyticComponent },
  ];
  
  @NgModule({
    imports: [
      CommonModule,
      BrowserAnimationsModule,
      FormsModule,
      ReactiveFormsModule,
      MatButtonModule,
      MatProgressBarModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      MatChipsModule,
      MatCardModule,
      MatSliderModule,
      MatTooltipModule,
      MatSelectModule,
      MatTableModule,
      MatSortModule,
      SharedModule,
      RouterModule.forChild(appRoutes)
    ],
    declarations: [
      AnalyticComponent
    ]
})
export class AnalyticModule { }
  