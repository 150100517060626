import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainService } from '../main/services/main.service'
import { MatSort, MatTableDataSource } from '@angular/material';
import * as FileSaver from 'file-saver';
import { EncodingHelper } from '../shared/helpers/encoding-helper'
import { DownloadDialogComponent } from '../main/download-dialog/download-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
    selector: 'app-analytic',
    templateUrl: './analytic.component.html',
    styleUrls: ['./analytic.component.scss']
})
export class AnalyticComponent implements OnInit {
    public loadingSongs: boolean = true;
    public loadingAnalytics: boolean = true;
    public songs: any[] = [];
    public rhythmDataSource: MatTableDataSource<any>;
    public scaleDataSource: MatTableDataSource<any>;
    public ids;
    public filters;
    public page;
    public pageSize;
    displayedColumnsScl: string[] = ['key', 'value'];
    displayedColumnsRhythm: string[] = ['rhythm', 'metre', 'shortestValue', 'barType', 'value']
    @ViewChild('rhythmSort') rhythmSort: MatSort
    @ViewChild('scaleSort') scaleSort: MatSort

    constructor(
        private route: ActivatedRoute,
        private mainService: MainService,
        public dialog: MatDialog,
    ) { }

    ngOnInit() {
        this.readQueryParams();
        this.getDataBasedOnParams();
    }

    private readQueryParams(): void {
        this.ids = this.route.snapshot.queryParams['ids'] ? this.route.snapshot.queryParams['ids'].split(',') : undefined;
        this.page = this.route.snapshot.queryParams['page'] || 0;
        this.pageSize = parseInt(this.route.snapshot.queryParams['size']) || 10;
        var filtersEncoded = this.route.snapshot.queryParams['filters'];
        this.filters = filtersEncoded ? EncodingHelper.atou(filtersEncoded) : '[]';
        this.filters = JSON.parse(this.filters);
    }

    private getDataBasedOnParams(): void {
        this.getSongs();
        this.getAnalytics();
    }

    private getSongs(): void {
        if (this.ids) {
            this.mainService.getEsacsByIds(this.ids)
                .subscribe(data => {
                    var response: any = data;
                    this.songs = response;
                    this.loadingSongs = false;
                });
        } else {
            this.mainService.getEsacs(this.page, this.pageSize, this.filters)
                .subscribe(data => {
                    var response: any = data;
                    this.songs = response.content;
                    this.loadingSongs = false;
                })
        }
    }

    private getAnalytics(): void {
        if (this.ids) {
            this.mainService.getEsacsAnalyticsByIds(this.ids)
                .subscribe(data => {
                    this.parseData(data);
                    this.loadingAnalytics = false;
                });
        } else {
            this.mainService.getEsacsAnalytics(this.page, this.pageSize, this.filters)
                .subscribe(data => {
                    this.parseData(data);
                    this.loadingAnalytics = false;
                })
        }
    }

    private parseData(data): void {
        this.rhythmDataSource = new MatTableDataSource(this.parseRhythmMap(data.rhythmStats));
        this.scaleDataSource = new MatTableDataSource(this.parseScaleMap(data.scaleStats));
        this.rhythmDataSource.sort = this.rhythmSort;
        this.scaleDataSource.sort = this.scaleSort;
        this.rhythmDataSource.sortingDataAccessor = (data, header) => data[header];
        this.scaleDataSource.sortingDataAccessor = (data, header) => data[header];
    }

    private parseRhythmMap(map): any[] {
        var dataTable = [];
        for (var key in map) {
            dataTable.push({
                value: map[key], ...JSON.parse(key)
            });
        }
        return dataTable;
    }

    private parseScaleMap(map): any[] {
        var dataTable = [];
        for (var key in map) {
            dataTable.push({
                key: key,
                value: map[key]
            });
        }
        return dataTable;
    }

    private downloadSongs(): void {
        this.dialog.open(DownloadDialogComponent).afterClosed().subscribe(fields => {
            if (fields == null) return;
            if (this.ids) {
                this.mainService.getEsacsTxtByIds(this.ids, fields)
                    .subscribe(data => {
                        const content = data
                        const blob = new Blob([content], { type: 'text/plain;charset=UTF-8' });
                        FileSaver.saveAs(blob, 'esacs.txt');
                    });
            } else {
                this.mainService.getEsacsTxt(this.page, this.pageSize, this.filters, fields)
                    .subscribe(data => {
                        const content = data
                        const blob = new Blob([content], { type: 'text/plain;charset=UTF-8' });
                        FileSaver.saveAs(blob, 'esacs.txt');
                    });
            }
        });
    }

    private downloadStats(): void {
        var csv: string = '';
        csv += `rhythm value, metre value, shortestValue value, rhythm count, barType value\n`;
        for (let stat of this.rhythmDataSource.connect().value) {
            csv += `\t${stat.rhythm},${stat.metre},${stat.shortestValue},${stat.barType},${stat.value}\n`
        }
        csv += '\nscale value, scale count\n'
        for (let stat of this.scaleDataSource.connect().value) {
            csv += `\t${stat.key},${stat.value}\n`
        }
        const blob = new Blob([csv], { type: 'text/plain;charset=UTF-8' });
        FileSaver.saveAs(blob, 'stats.csv');
    }
}
