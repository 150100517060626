import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../shared/services/authentication.service';
@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    registerForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';
    isRegistrationSuccessful = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private http: HttpClient) { }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            firstname: new FormControl('', [Validators.required]),
            lastname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required]),
            passwordRepeat: new FormControl('', [Validators.required])
        });

        // reset login status
        this.authenticationService.logout();
    }

    // convenience getter for easy access to form fields
    get f() { return this.registerForm.controls; }

    getErrorMessage(field) {
        if (field.hasError('required')) return 'You must enter a value';
        if (field.hasError('email')) return 'Not a valid email';
    }

    onSubmit() {
        this.submitted = true;
        this.error = '';

        // stop here if form is invalid
        if (this.registerForm.invalid) {
            return;
        }
        if (this.f.password.value !== this.f.passwordRepeat.value) {
            this.error = "Provided passwords don't match"
            return;
        }

        var requestBody = { email: this.f.email.value, password: this.f.password.value, name: this.f.firstname.value, lastName: this.f.lastname.value }
        this.loading = true;
        this.setControlsEnabled(false);
        this.http.post<any>(`/api/register`, requestBody)
            .subscribe(
                data => {
                    this.isRegistrationSuccessful=true;
                },
                error => {
                    this.error = 'Error while registering. Check data format.'
                    this.loading = false;
                    this.setControlsEnabled(true);
                });
    }

    setControlsEnabled(value : boolean):void{
        if(value){
            this.f.firstname.enable();
            this.f.lastname.enable();
            this.f.email.enable();
            this.f.password.enable();
            this.f.passwordRepeat.enable();
        }else{
            this.f.firstname.disable();
            this.f.lastname.disable();
            this.f.email.disable();
            this.f.password.disable();
            this.f.passwordRepeat.disable();
        }
    }
}
