import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import {
  melody_regexp,
  keySig_regexp,
  keyShortestValue_regexp,
  keyTonic_regexp,
  keyMetre_regexp,
} from "../../shared/consts/regexp";

import * as FileSaver from "file-saver";

import { MidiPlayerService } from "./../../main/services/midi-player.service";
import { MainService } from "./../../main/services/main.service";
import { DownloadDialogComponent } from "./../../main/download-dialog/download-dialog.component";
import { MessageDialogService } from "../../shared/services/message-dialog.service";

@Component({
  selector: "app-midi-convert-result",
  templateUrl: "./midi-convert-result.component.html",
  styleUrls: ["./midi-convert-result.component.scss"],
})
export class MidiConvertResultComponent implements OnInit, OnDestroy {
  @Input() esac;
  public form: FormGroup;
  public speedData = this.midiPlayerService.getSpeedData();
  private createNewEsacFromURL = "/api/esac";
  public errors: String[];
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
    private mainService: MainService,
    public dialog: MatDialog,
    private midiPlayerService: MidiPlayerService,
    private messageDialogService: MessageDialogService
  ) { }

  ngOnInit() {
    this.form = this.fillForm();
  }

  ngOnDestroy() {
    this.stopMidi();
  }

  private fillForm(): FormGroup {
    const esac = this.esac;
    return new FormGroup({
      name: new FormControl(esac.name),
      title: new FormControl(esac.title),
      source: new FormControl(esac.source),
      region: new FormControl(esac.region),
      function: new FormControl(esac.function),
      signature: new FormControl(esac.signature),
      newKey: new FormGroup({
        sig: new FormControl(esac.newKey.sig, [
          Validators.required,
          Validators.pattern(keySig_regexp),
        ]),
        shortestValue: new FormControl(esac.newKey.shortestValue, [
          Validators.required,
          Validators.pattern(keyShortestValue_regexp),
        ]),
        tonic: new FormControl(esac.newKey.tonic, [
          Validators.required,
          Validators.pattern(keyTonic_regexp),
        ]),
        metre: new FormControl(esac.newKey.metre, [
          Validators.required,
          Validators.pattern(keyMetre_regexp),
        ]),
      }),
      melody: new FormControl(esac.melody, [
        Validators.required,
        Validators.pattern(melody_regexp),
      ]),
      remarks: new FormControl(esac.remarks),
    });
  }

  public isMidiPlaying(): boolean {
    return this.esac.isPlaying && this.midiPlayerService.isMidiPlaying();
  }

  public slowDownMidi(): void {
    this.midiPlayerService.slowDownMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  public speedUpMidi(): void {
    this.midiPlayerService.speedUpMidi();
    this.speedData = this.midiPlayerService.getSpeedData();
  }

  public playMidi(): void {
    const esac = this.form.value;
    this.mainService.esacToMidi(esac).subscribe(
      (data) => {
        this.esac.isPlaying = true;
        this.midiPlayerService.setMidiSong(data);
        this.midiPlayerService.playMidi();
      },
      (error) => {
        this.messageDialogService.displayMessageDialog("Error playing MIDI");
      }
    );
  }

  public stopMidi(): void {
    this.esac.isPlaying = false;
    this.midiPlayerService.stopMidi();
  }

  public downloadEsac(): void {
    this.dialog
      .open(DownloadDialogComponent)
      .afterClosed()
      .subscribe((fields) => {
        if (fields == null) return;
        const esac = { ...this.esac, ...this.form.value };
        const content = this.esacToString(esac, fields);
        const blob = new Blob([content], { type: "text/plain" });
        FileSaver.saveAs(blob, esac.signature + "_" + esac.title + ".txt");
      });
  }

  public addEsac(): any {
    this.errors = [];
    this.loading = true;
    this.setFormEnabled(false);
    return this.http.put(this.createNewEsacFromURL, this.form.value).subscribe(
      (data) => {
        const response: any = data;
        if (response.totalSuccesses == 1) {
          this.messageDialogService.displayMessageDialog(
            "EsAC added successfully"
          );
        } else {
          this.errors = response.failures[0].errors;
          this.loading = false;
          this.setFormEnabled(true);
        }
      },
      (error) => {
        this.messageDialogService.displayMessageDialog("Error adding EsAC");
        this.loading = false;
        this.setFormEnabled(true);
      }
    );
  }

  private setFormEnabled(value: boolean): void {
    if (value) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }

  private esacToString(esac, fields): string {
    fields = fields || ["CUT", "REG", "TRD", "SIG", "KEY", "MEL", "BEM"];
    let string = "";
    string += esac.name != null ? esac.name + "\r\n" : "";
    string +=
      esac.title != null && fields.includes("CUT")
        ? "CUT[" + esac.title + "]\r\n"
        : "";
    string +=
      esac.region != null && fields.includes("REG")
        ? "REG[" + esac.region + "]\r\n"
        : "";
    string +=
      esac.source != null && fields.includes("TRD")
        ? "TRD[" + esac.source + "]\r\n"
        : "";
    string +=
      esac.function != null && fields.includes("FKT")
        ? "FKT[" + esac.function + "]\r\n"
        : "";
    string +=
      esac.signature != null && fields.includes("SIG")
        ? "SIG[" + esac.signature + "]\r\n"
        : "";
    string +=
      esac.key != null && fields.includes("KEY")
        ? "KEY[" + esac.key + "]\r\n"
        : "";
    string +=
      esac.melody != null && fields.includes("MEL")
        ? "MEL[" + esac.melody.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.remarks != null && fields.includes("BEM")
        ? "BEM[" + esac.remarks + "]\r\n"
        : "";
    string +=
      esac.melodyIntervals != null && fields.includes("MEL_SEM")
        ? "MEL_SEM[" + esac.melodyIntervals.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRaw != null && fields.includes("MEL_RAW")
        ? "MEL_RAW[" + esac.melodyRaw.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRawSimple != null && fields.includes("NO_REP")
        ? "NO_REP[" + esac.melodyRawSimple.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.melodyRhythm != null && fields.includes("RTM")
        ? "RTM[" + esac.melodyRhythm.replace(/\n/g, "\r\n    ") + "]\r\n"
        : "";
    string +=
      esac.scl != null && fields.includes("SCL_DEG")
        ? "SCL_DEG[" + esac.scl + "]\r\n"
        : "";
    string +=
      esac.sclIntervals != null && fields.includes("SCL_SEM")
        ? "SCL_SEM[" + esac.sclIntervals + "]\r\n"
        : "";
    string +=
      esac.phraseNum != null && fields.includes("PHR_NO")
        ? "PHR_NO[" + esac.phraseNum + "]\r\n"
        : "";
    string +=
      esac.barsNumByPhrase != null && fields.includes("PHR_BARS")
        ? "PHR_BARS[" + esac.barsNumByPhrase + "]\r\n"
        : "";
    string +=
      esac.cadByPhrase != null && fields.includes("PHR_CAD")
        ? "PHR_CAD[" + esac.cadByPhrase + "]\r\n"
        : "";
    string +=
      esac.accByPhrase != null && fields.includes("ACC")
        ? "ACC[" + esac.accByPhrase + "]\r\n"
        : "";
    string += "\r\n";

    return string;
  }
}
