import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatCardModule,
  MatButtonModule,
  MatIconModule,
  MatMenuModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatChipsModule,
  MatCheckboxModule,
  MatChip,
  MatSelectModule,
  MatSliderModule,
  MatTooltipModule,
} from "@angular/material";

import { SharedModule } from "../shared/shared.module";

import { MainService } from "./services/main.service";
import { MidiPlayerService } from "./services/midi-player.service";
import { EsacService } from "./services/esac.service";

import { MainComponent } from "./main.component";
import { EsacAddDialogComponent } from "./esac-add-dialog/esac-add-dialog.component";
import { EsacConvertDialogComponent } from "./esac-convert-dialog/esac-convert-dialog.component";
import { EsacAddNewComponent } from "./esac-add-dialog/esac-add-new/esac-add-new.component";
import { EsacAddFileComponent } from "./esac-add-dialog/esac-add-file/esac-add-file.component";
import { EsacEditDialogComponent } from "./esac-edit-dialog/esac-edit-dialog.component";
import { EsacRecodeDialogComponent } from "./esac-recode-dialog/esac-recode-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { DownloadDialogComponent } from "./download-dialog/download-dialog.component";
import { FieldsDialogComponent } from "./fields-dialog/fields-dialog.component";
import { EsacCardComponent } from "./esac-convert-dialog/esac-card/esac-card.component";
import { MainCardComponent } from "./main-card/main-card.component";
import { OneEsacConvertDialogComponent } from "./one-esac-convert-dialog/one-esac-convert-dialog.component";
import { NotesDialogComponent } from "./notes-dialog/notes-dialog.component";
import { FindReplaceDialog } from "./find-replace-dialog/find-replace-dialog.component";
import { FindReplaceResultDialog } from "./find-replace-dialog/find-replace-result-dialog/find-replace-result-dialog.component";

const appRoutes: Routes = [{ path: "", component: MainComponent }];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatChipsModule,
    MatSelectModule,
    MatSliderModule,
    MatTooltipModule,
    MatCheckboxModule,
    SharedModule,
    RouterModule.forChild(appRoutes),
  ],
  declarations: [
    MainComponent,
    EsacAddDialogComponent,
    EsacConvertDialogComponent,
    EsacAddNewComponent,
    EsacAddFileComponent,
    EsacEditDialogComponent,
    EsacRecodeDialogComponent,
    ConfirmDialogComponent,
    DownloadDialogComponent,
    FieldsDialogComponent,
    EsacCardComponent,
    MainCardComponent,
    OneEsacConvertDialogComponent,
    NotesDialogComponent,
    FindReplaceDialog,
    FindReplaceResultDialog,
  ],
  entryComponents: [
    EsacAddDialogComponent,
    EsacConvertDialogComponent,
    EsacEditDialogComponent,
    EsacRecodeDialogComponent,
    ConfirmDialogComponent,
    DownloadDialogComponent,
    FieldsDialogComponent,
    OneEsacConvertDialogComponent,
    NotesDialogComponent,
    FindReplaceDialog,
    FindReplaceResultDialog,
  ],
  providers: [MainService, MidiPlayerService, EsacService],
})
export class MainModule {}
