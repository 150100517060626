import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material";
import { MainService } from "../services/main.service";
import { FindReplaceResultDialog } from "./find-replace-result-dialog/find-replace-result-dialog.component";
import { MessageDialogComponent } from "../../shared/message-dialog/message-dialog.component";

@Component({
  selector: "find-replace-dialog",
  templateUrl: "./find-replace-dialog.component.html",
  styleUrls: ["./find-replace-dialog.component.scss"],
})
export class FindReplaceDialog implements OnInit {
  public esac: any;
  public title: string;
  public page: any;
  public pageSize: any;
  public filters: any;
  public afterClosed: any;
  public items: Array<any> = [{ field: "", pattern: "", replacement: "" }];
  public availableFields: Object[] = [
    { field: "name", placeholder: "Catalog" },
    { field: "title", placeholder: "Title (CUT)" },
    { field: "region", placeholder: "Region (REG)" },
    { field: "source", placeholder: "Source (TRD)" },
    { field: "function", placeholder: "Function (FKT)" },
    { field: "signature", placeholder: "Signature (SIG)" },
    { field: "remarks", placeholder: "Remarks (BEM)" },
    { field: "sig", placeholder: "Key signature" },
    { field: "shortestValue", placeholder: "Shortest value" },
    { field: "tonic", placeholder: "Tonic" },
    { field: "metre", placeholder: "Metre" },
    { field: "melody", placeholder: "Melody (MEL)" },
  ];
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FindReplaceDialog>,
    public dialog: MatDialog,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.title = this.data.selected
      ? `${this.data.selected.size} songs`
      : this.data.page
      ? "the current page"
      : "all pages";
    this.page = this.data.page;
    this.pageSize = this.data.pageSize;
    this.filters = this.data.filters;
    this.afterClosed = this.data.afterClosed;
  }

  public addItem() {
    this.items.push({ field: "", pattern: "", replacement: "" });
  }

  public removeItem(index) {
    this.items.splice(index, 1);
  }

  public submit() {
    this.loading = true;
    var observable = this.data.selected
      ? this.mainService.findReplaceByIds(
          Array.from(this.data.selected),
          this.items
        )
      : this.mainService.findReplaceByPage(
          this.page,
          this.pageSize,
          this.filters,
          this.items
        );
    observable.subscribe(
      (result) => {
        this.loading = false;
        this.dialogRef.close();
        this.dialog
          .open(FindReplaceResultDialog, {
            data: result,
          })
          .afterClosed()
          .subscribe(this.afterClosed);
      },
      () => {
        this.loading = false;
        this.dialog.open(MessageDialogComponent, {
          autoFocus: true,
          minWidth: 300,
          disableClose: true,
          data: "Error while processing EsACs",
        });
      }
    );
  }

  public noFieldSelected() {
    return this.items.filter((i) => i.field).length === 0;
  }
}
