import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Observable, forkJoin, of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class MainService {
  private urlGetEsacs = "/api/esac/list";
  private urlEsac2Midi = "/api/esac2midi";
  private urlEsac2MidiFile = "/api/esac2midi?format=file";
  private urlEsacsAnalytics = "/api/esac/stats";
  private urlEsacFindReplace = "/api/esac/findreplace";
  private esacEndpoint = "/api/esac";

  constructor(private http: HttpClient) {}

  getEsacs(page = 0, pageSize = 10, terms: any = []): Observable<any> {
    let params = new HttpParams();
    if (page > 0) {
      params = params.set("page", page.toString());
    }
    params = params.set("size", pageSize.toString());
    return this.http.post(this.urlGetEsacs, terms, { params });
  }

  getEsacsByIds(ids: number[]) {
    let params = new HttpParams().set("ids", "1");
    return this.http.post(this.urlGetEsacs, ids, { params });
  }

  getEsacsTxt(
    page = 0,
    pageSize = 10,
    terms: any = [],
    fields = []
  ): Observable<any> {
    let params = new HttpParams().set("totxt", "1");
    if (page > 0) {
      params = params.set("page", page.toString());
    }
    if (fields) {
      params = params.set("fields", fields.toString());
    }
    params = params.set("size", pageSize.toString());
    return this.http.post(this.urlGetEsacs, terms, {
      params,
      responseType: "text",
    });
  }

  getEsacsZip(
    page = 0,
    pageSize = 10,
    terms: any = [],
    fields = []
  ): Observable<any> {
    let params = new HttpParams()
      .set("tozip", "1")
      .set("size", pageSize.toString());
    if (page > 0) {
      params = params.set("page", page.toString());
    }
    if (fields) {
      params = params.set("fields", fields.toString());
    }
    return this.http.post(this.urlGetEsacs, terms, {
      params: params,
      responseType: "blob",
    });
  }

  getEsacsTxtByIds(ids: number[], fields = []) {
    let params = new HttpParams().set("totxt", "1").set("ids", "1");
    if (fields) {
      params = params.set("fields", fields.toString());
    }
    return this.http.post(this.urlGetEsacs, ids, {
      params,
      responseType: "text",
    });
  }

  getEsacsZipByIds(ids: number[], fields = []) {
    let params = new HttpParams().set("tozip", "1").set("ids", "1");
    if (fields) {
      params = params.set("fields", fields.toString());
    }
    return this.http.post(this.urlGetEsacs, ids, {
      params: params,
      responseType: "blob",
    });
  }

  getEsacsAnalytics(page = 0, pageSize = 10, terms: any = []): Observable<any> {
    let params = new HttpParams();
    if (page > 0) {
      params = params.set("page", page.toString());
    }
    params = params.set("size", pageSize.toString());
    return this.http.post(this.urlEsacsAnalytics, terms, { params });
  }

  getEsacsAnalyticsByIds(ids: number[]) {
    let params = new HttpParams().set("ids", "1");
    return this.http.post(this.urlEsacsAnalytics, ids, { params });
  }

  esacToMidi(esac: any) {
    return this.http.post(this.urlEsac2Midi, esac);
  }

  esacToMidiFile(esac: any) {
    return this.http.post(this.urlEsac2MidiFile, esac, {
      responseType: "arraybuffer",
    });
  }

  multipleEsacToMidiFile(esacs: any[]) {
    const httpCalls = [];
    for (const esac of esacs) {
      httpCalls.push(
        this.http
          .post(this.urlEsac2MidiFile, esac, { responseType: "arraybuffer" })
          .pipe(
            map((res) => res),
            catchError((e) => of(esac))
          )
      );
    }

    return forkJoin(httpCalls);
  }

  deleteEsac(id: any): Observable<any> {
    return this.http.delete(this.esacEndpoint + "/" + id);
  }

  deleteEsacs(terms: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: terms,
    };
    return this.http.delete(this.esacEndpoint + "/list", options);
  }

  deleteEsacsByIds(ids: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      params: new HttpParams().set("ids", "1"),
      body: ids,
    };
    return this.http.delete(this.esacEndpoint + "/list", options);
  }

  updateEsac(id: any, result): Observable<any> {
    return this.http.patch(this.esacEndpoint + "/" + id, result);
  }

  recodeEsac(id: any, tonic: String, shortestValue: string): Observable<any> {
    return this.http.patch(`${this.esacEndpoint}/${id}/recode`, null, {
      params: new HttpParams()
        .set("tonic", tonic[0].toUpperCase() + tonic.slice(1))
        .set("shortestValue", shortestValue),
    });
  }

  findReplaceByPage(page = 0, pageSize = 10, searchFilters, items) {
    let params = new HttpParams();
    if (page > 0) {
      params = params.set("page", page.toString());
    }
    params = params.set("size", pageSize.toString());
    return this.http.post(
      this.urlEsacFindReplace,
      { searchFilters, items },
      { params }
    );
  }

  findReplaceByIds(ids, items) {
    const params = new HttpParams().set("ids", "1");
    return this.http.post(this.urlEsacFindReplace, { ids, items }, { params });
  }
}
