import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  login(email: string, password: string) {
    return this.http.post<any>(`/api/authenticate`, { email, password }).pipe(
      map((user) => {
        // login successful if there's a user in the response
        if (user) {
          // store user details and basic auth credentials in local storage
          // to keep user logged in between page refreshes
          user.authdata = window.btoa(email + ":" + password);
          localStorage.setItem("currentUser", JSON.stringify(user));
        }

        return user;
      })
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
  }

  isLoggedIn(): boolean {
    return localStorage.getItem("currentUser") ? true : false;
  }

  getLoggedUser() {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  verifyToken(token: string) {
    let params = new HttpParams().set("token", token);
    return this.http.get("/api/registrationConfirm", { params });
  }

  resetPassword(email: string) {
    let params = new HttpParams().set("email", email);
    return this.http.post("/api/resetPassword", null, { params });
  }

  changePassword(token: string, password: string) {
    let params = new HttpParams().set("token", token);
    return this.http.post("/api/changePassword", password, { params });
  }
}
